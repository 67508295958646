import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from '../../components/Header';

// Actions
import { gameActions } from '../../actions';

class New extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instructors: [],
      name: '',
      instructor: '',
      type: 'csv',
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.setType = this.setType.bind(this);
    this.submit = this.submit.bind(this);
    this.hubGetUsers = this.hubGetUsers.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.role === 'administrator') this.hubGetUsers();
    else this.setState({ instructors: [user], instructor: user._id });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      data: newProps.data,
    });
  }

  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  setType(type) {
    this.setState({ type });
  }

  hubGetUsers() {
    fetch(
      process.env.REACT_APP_API_URL +
        '/hub/users?role[]=instructor&role[]=administrator',
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ instructors: data });
      });
  }

  submit() {
    const data = {
      name: this.state.name,
      instructor: this.state.instructor,
      access_type: this.state.type,
    };
    this.props.dispatch(gameActions.createGame(data));
  }

  render() {
    const { user, game } = this.props;
    const { name, instructor, type, instructors } = this.state;

    // If there's game data, then redirect to it
    if (game.data && game.data._id)
      return <Redirect to={`/games/${game.data._id}`} />;

    const instructorOpts = instructors.map((i, ii) => {
      const name =
        i.name.length > 0 && i.lastname.length > 0
          ? `${i.name} ${i.lastname}`
          : i.email;
      return (
        <option
          key={`ins-${ii}`}
          onClick={this.handleChange}
          value={i._id}>{`${name} (${i.email})`}</option>
      );
    });

    const canSubmit =
      name.length > 0 && type.length > 0 && instructor.length > 0
        ? true
        : false;

    return (
      <div className=''>
        <Header />

        <div className='row mt-5'>
          <div className='col-12 col-lg-6 offset-lg-3'>
            <h3 className='text-center mb-5'>New Survey</h3>

            <hr className='mb-5' />

            <div className='row'>
              <div className='col-12'>
                <p className='text-center lead mb-5'>
                  Give your survey a name.
                  <br />
                  (You can update this later)
                </p>
                <div className='input-group input-group-lg mb-4'>
                  <input
                    name='name'
                    className='form-control'
                    onChange={this.handleChange}
                    placeholder='Give your survey a name (Required)'
                  />
                </div>
              </div>
              {user.role === 'administrator' && (
                <div className='col-12'>
                  <div className='input-group input-group-lg'>
                    <select
                      name='instructor'
                      className='form-control'
                      value={instructor}
                      onChange={this.handleChange}
                      disabled={user.role === 'instructor'}>
                      <option value=''>Select Author (Required)</option>
                      {instructorOpts}
                    </select>
                  </div>
                </div>
              )}
            </div>

            <div className='row mt-4 mb-5'>
              <div className='text-center col-12 mt-5'>
                <button
                  className='btn btn-outline-primary btn-lg mr-2'
                  disabled={!canSubmit}
                  onClick={this.submit}>
                  Create
                </button>
                <Link to='/games' className='btn btn-light btn-lg ml-2'>
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, player, game } = state;
  return {
    user: authentication.user,
    game,
    player,
  };
}

const connectedGamesNew = connect(mapStateToProps)(New);
export { connectedGamesNew as GameNew };
