import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import Vimeo from '@u-wave/react-vimeo';

// Actions
import { gameActions } from '../../actions';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
// import AffixWrapper from '../../components/AffixWrapper';
import AlignmentMatrix from './report_alignment_matrix';
// import DotsPlot from './report_dots_plot';
// import NetworkChart from './report_network';
import Icon from '../../assets/app_icon.png';
// import Player from '../../assets/playerdemo.png';

const TipCard = (props) => {
  const { name, title, description, placeholder, values, onChange } = props;
  return (
    <div className='card shadow'>
      <div className='card-body text-center'>
        <div
          className='custom-control custom-switch mb-3 mt-2'
          style={{ transform: 'scale(1.5)' }}>
          <input
            type='checkbox'
            id={name}
            name={`${name}_check`}
            className='custom-control-input'
            onChange={onChange}
          />
          <label className='custom-control-label' htmlFor={name}>
            &nbsp;
          </label>
        </div>
        <h5 className='mb-4'>{title}</h5>
        <p className='mb-5'>{description}</p>
        <textarea
          className='form-control'
          rows={4}
          name={`${name}_text`}
          onChange={onChange}
          placeholder={placeholder}>
          {values[`${name}_text`]}
        </textarea>
      </div>
    </div>
  );
};

const tips = [
  {
    name: 'homegenity',
    title: 'Team Homogenity',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
    placeholder:
      'Type a description of what we intend to do and how we intend to measure it.',
  },
];

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_dots: false,
      show_dots: false,
      show_matrix: false,
      show_checklist: false,
      show_summary: false,
      affix: false,
      item: this.props.item || null,
      grouping: 'All',
      grouping_alt: '',
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleTipChange = this.handleTipChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    this.setState({ [n]: v });
    if (n === 'grouping') {
      this.setState({ grouping_alt: '' });
    }
  }

  handleTipChange(e) {
    const { type, value, name, checked } = e.target;
    const { data } = this.props.game;
    let item = { ...data };
    if (type === 'checkbox') {
      // console.log(type, value, name, checked);
      if (item.metadata) item.metadata[name] = checked;
      else item.metadata = { [name]: checked };
    } else {
      // console.log(type, value, name, value);
      if (item.metadata) item.metadata[name] = value;
      else item.metadata = { [name]: value };
    }
    this.props.dispatch(gameActions.updateGame(item));
  }

  render() {
    const { game } = this.props;
    const { metadata } = game.data;
    const {
      grouping,
      grouping_alt,
      modal_dots,
      show_dots,
      show_matrix,
      show_checklist,
      show_summary,
    } = this.state;

    const reportGroups = game && game.data ? game.data.organization_groups : [];
    const reportGroupsAlts =
      game && game.data && grouping && grouping !== 'All' && reportGroups.length
        ? reportGroups.find((g, gi) => `group_${gi}` === grouping).items
        : [];
    let reportPlayers = game && game.data ? game.data.players : [];
    if (
      grouping &&
      grouping !== 'All' &&
      grouping_alt &&
      grouping_alt !== 'All'
    ) {
      reportPlayers = reportPlayers.filter((p) => {
        const ans = p.survey.find(
          (a) => a && a.question_id === grouping && a.response === grouping_alt
        );
        console.log(grouping, grouping_alt, ans);
        return ans ? true : false;
      });
    }

    console.log(reportPlayers);

    return (
      <div>
        <Header />

        <div className='d-none d-print-block mt-5'>
          <h1 className='text-center text-dark title font-weight-light'>
            ALIGNED
          </h1>
          <h4 className='text-center text-muted title font-weight-light'>
            {game.data.name}
          </h4>
        </div>

        <div className='row mb-5'>
          <div className='col-12 col-lg-10 offset-lg-1 text-center'>
            <h3 className='text-center mt-5 mb-5'>Report</h3>
            <hr className='mb-4' />

            {/* <h2 className='font-weight-light mt-5 mb-4'>
              Step 1: Understanding Your Report
            </h2>
            <p className='d-none d-print-block lead'>
              This is a tool you can use to motivate and guide organizational
              change. Use it to assess alignment between your environment and
              your strategy, as well as your strategy and your organization’s
              structure. Then filter your data to gauge alignment within your
              organization’s various groups. Finally, use this report to assess
              the extent to which individual people in your organization are
              aligned, and learn from those already in the place you are trying
              to go.
            </p>
            <div className='d-print-none'>
              <p className='lead'>Learn about this report.</p>
              <iframe
                title='video'
                src='https://player.vimeo.com/video/542903798'
                width='540'
                height='360'
                frameBorder='0'
                allow='autoplay; fullscreen'
                allowFullScreen></iframe>

              
            </div> */}

            {/* <hr className='mt-5 mb-4' /> */}

            {/* <AffixWrapper className='sticky-menu d-print-none' offset={900}>
              <div className='row'>
                <label className='col-2 offset-2 col-form-label'>
                  Filter Respondants
                </label>
                <div className='col-3'>
                  <select
                    name='grouping'
                    className='form-control mb-0'
                    value={grouping}
                    onChange={this.handleChange}>
                    <option>All</option>
                    {reportGroups.map((g, gi) => {
                      return (
                        <option
                          key={`g-${gi}`}
                          value={`group_${gi}`}
                          // selected={`group_${gi}` === grouping ? true : false}
                        >
                          {g.name ? g.name : g}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='col-3'>
                  <select
                    name='grouping_alt'
                    value={grouping_alt}
                    className='form-control mb-0'
                    onChange={this.handleChange}>
                    <option>All</option>
                    {reportGroupsAlts.map((gitem, gi) => {
                      return (
                        <option
                          key={`ga-${gi}`}
                          value={gitem}
                          // selected={gitem === grouping_alt ? true : false}
                        >
                          {gitem}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </AffixWrapper> */}

            {/* <div className='row pt-5 mb-5 pb-5'>
              <div className='col-12 col-lg-6 offset-lg-3'>
                <h2 className='font-weight-light mb-4'>Step 2: Direction</h2>
                <p className='lead'>
                  In this section of your report, you will identify how aligned
                  your strategy is with your environment. You will also be able
                  to gauge how well your structure aligns with your strategy.
                </p>
              </div>
              <div className='col-12 mt-5'>
                {!show_dots ? (
                  <button
                    className='btn btn-outline-primary btn-lg'
                    onClick={() => this.setState({ show_dots: true })}>
                    Review
                  </button>
                ) : (
                  <DotsPlot players={reportPlayers} />
                )}
              </div>
            </div>

            <hr className='mt-5 mb-4' /> */}

            <div className='row pt-5 mb-5 pb-5'>
              <div className='col-12 col-lg-8 offset-lg-2'>
                <h2 className='font-weight-light mb-4'>Assesment</h2>
                <p className='lead'>
                  In the section of your report, you will identify how prepared
                  your organization (or team) is to generate (positive) results
                  under varying environmental conditions or shifts in strategic
                  intent.
                </p>
              </div>
              <div className='col-12 mt-5'>
                {!show_matrix ? (
                  <button
                    className='btn btn-outline-primary btn-lg'
                    onClick={() => this.setState({ show_matrix: true })}>
                    Review
                  </button>
                ) : (
                  <AlignmentMatrix
                    grouping={grouping}
                    organization_groups={reportGroups}
                    players={reportPlayers}
                  />
                )}
              </div>
            </div>

            <hr className='mt-5 mb-4' />

            {/* <div className='row pt-5 mb-5 pb-5'>
              <div className='col-12 col-lg-8 offset-lg-2'>
                <h2 className='font-weight-light mt-5 mb-4'>Agile Checklist</h2>
                <p className='lead'>
                  Your assessment profiles how Agile you are at the moment. In
                  this section, you can identify tasks that can help improve or
                  maintain your organizations agility. Check off the best
                  practices you’ve implemented to keep track of how your
                  organization evolves.
                </p>
              </div>
              <div className='col-12 mt-4'>
                {!show_checklist ? (
                  <button
                    className='btn btn-outline-primary btn-lg'
                    onClick={() => this.setState({ show_checklist: true })}>
                    Review
                  </button>
                ) : (
                  <div className='col-12 col-lg-8 offset-lg-2'>
                    {tips.map((t) => (
                      <TipCard
                        values={
                          metadata && metadata[t.name] ? metadata[t.name] : {}
                        }
                        // onChange={this.handleTipChange}
                        {...t}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>

            <hr className='mt-5 mb-4' /> */}

            {/* <div className='row pt-5 mb-5 pb-5'>
              <div className='col-12 col-lg-8 offset-lg-2'>
                <h2 className='font-weight-light mt-5 mb-4'>
                  Using Your Report
                </h2>
                <p className='lead'>
                  The two visualizations at the core of the Aligned report are
                  designed to work in unison. This section helps outline how to
                  draw conclusions that span the data presented in each
                  visualization, while also describing how periodically
                  conducting this analysis can help track how your organization
                  evolves.
                </p>
              </div>
              <div className='col-12 mt-4'>
                {!show_summary ? (
                  <button
                    className='btn btn-outline-primary btn-lg'
                    onClick={() => this.setState({ show_summary: true })}>
                    Review
                  </button>
                ) : (
                  <div className='d-print-none'>
                    <iframe
                      title='video'
                      src='https://player.vimeo.com/video/542903798'
                      width='540'
                      height='360'
                      frameBorder='0'
                      allow='autoplay; fullscreen'
                      allowFullScreen></iframe>
                    <br />
                    <a
                      href='https://usebetta.com/aligned/aligned-use-cases/'
                      target='_blank'
                      rel='noreferrer'
                      className='btn btn-light text-primary mt-4 mb-5'>
                      Use Case Descriptions
                    </a>
                  </div>
                )}
              </div>
            </div> */}
          </div>
        </div>

        {/* <Modal
          isOpen={modal_dots}
          centered={true}
          size={'lg'}
          toggle={() => this.setState({ modal_dots: !modal_dots })}>
          <ModalHeader
            toggle={() => this.setState({ modal_dots: !modal_dots })}>
            Key Terms
          </ModalHeader>
          <ModalBody className='text-left'>
            <div className='row'>
              <div className='col-6'>
                <h5 className='mb-3'>EXPLORERS</h5>
                <p>
                  <b className='text-primary'>Environment</b>: Best suited to
                  rapidly changing environments.
                </p>
                <p>
                  <b className='text-primary'>Strategy</b>: Overarching goal
                  linking a number of objectives. Wide, temporally varying
                  scope. Constantly searching for new competitive advantages,
                  typically being high perceived quality.
                </p>
                <p>
                  <b className='text-primary'>
                    Structure/Organizational Design:
                  </b>
                </p>

                <div className='pl-3'>
                  <p>
                    <b className='text-primary'>Architecture</b>: Decentralized
                    direction of change activities. Loosely coupled business
                    units. Non-pervasive monitoring system. Monetary and
                    non-monetary incentives tied to innovation. Hiring and
                    promotion criteria support search for new competitive
                    advantages.
                  </p>
                  <p>
                    <b className='text-primary'>Routines</b>: Identifying and
                    developing innovations arising from projects. Learning
                    outside the defined domain. Substantial organizational
                    slack. Strengths in variation.
                  </p>
                  <p>
                    <b className='text-primary'>Culture</b>: Creativity.
                    Tolerance for failure and risk taking encouraged. Maverick
                    employees.
                  </p>
                </div>
              </div>
              <div className='col-6'>
                <h5 className='mb-3'>EXPLOITERS</h5>
                <p>
                  <b className='text-primary'>Environment</b>: Best suited to
                  stable environments.
                </p>

                <p>
                  <b className='text-primary'>Strategy</b>: Clearly defined
                  goal. Narrow, temporally stable scope. Continuously refining
                  existing competitive advantage, typically being low cost
                  leadership.
                </p>

                <p>
                  <b className='text-primary'>
                    Structure/Organizational Design:
                  </b>
                </p>

                <div className='pl-3'>
                  <p>
                    <b className='text-primary'>Architecture</b>: Centralized
                    direction of change activities. Tightly coupled business
                    units. Pervasive monitoring system. Monetary incentives tied
                    to specified tasks. Hiring and promotion criteria support
                    existing competitive advantage.
                  </p>
                  <p>
                    <b className='text-primary'>Routines</b>: Managing
                    interdependence among known tasks. Incremental learning
                    within the defined domain. No organization slack. Strengths
                    in selection and retention.
                  </p>
                  <p>
                    <b className='text-primary'>Culture</b>: Execution. Low
                    tolerance for failure. Programmed employees.
                  </p>
                </div>
              </div>
              <div className='col-12'>
                <hr />
                <p>
                  <i>
                    March, J.G. 1991. Exploration and exploitation in
                    organizational learning. Organizational Science. 2(1):71–87.
                  </i>
                </p>
                <p>
                  <i>
                    Näslund, B. 1964. Organizational slack. Ekonomisk Tidskrift.
                    66(1):26–31.
                  </i>
                </p>
              </div>
            </div>
          </ModalBody>
        </Modal> */}

        <Modal isOpen={false} centered={true} size={'lg'}>
          <ModalBody className='text-center'>
            <div className='p-5'>
              <img src={Icon} style={{ width: '200px' }} alt='Aligned' />
              <p className='lead mt-5'>
                Our results portal is still in development.
              </p>
              <p className='lead mb-5'>
                We are currently working on your report and will be in touch via
                email.
              </p>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(Report);
