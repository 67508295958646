import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class AlignmentMatrix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: '',
      grouping: this.props.grouping,
      avg: false,
    };
    // Bindings
    this.handleChange = this.handleChange.bind(this);
  }

  // componentDidMount() {}

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    if (e.target.type === 'checkbox') this.setState({ [n]: e.target.checked });
    else this.setState({ [n]: v });
  }

  getByName(answers = [], key = '') {
    const ans = answers.find((answer) => {
      return answer && answer.question_name == key;
    });
    return ans && ans.response ? ans.response : null;
  }

  getByType(answers = [], type = '') {
    return answers.filter(
      (answer) => answer && answer.question_cat.find((c) => c.category === type)
    );
  }

  render() {
    const { organization_groups, players, grouping } = this.props;
    const { avg } = this.state;

    // console.log({ data });

    // const altsCounterStructure = [0, 0, 0, 0];
    // const altsCounterStrategy = [0, 0, 0, 0];

    const respondants = players
      .filter((p) => p.survey && p.survey.length > 0)
      .map((p) => {
        // Demographics
        const gender = this.getByName(p.survey, 'gender');
        const tenure = this.getByName(p.survey, 'tenure');
        const rank = this.getByName(p.survey, 'rank');
        const group = this.getByName(p.survey, 'group');
        p.gender = gender;
        p.tenure = tenure;
        p.rank = rank;
        p.group = group;
        return p;
      })
      // .filter((p) => {
      //   console.log({ p });
      //   const filters = ['grouping'];
      //   const enabledFilters = filters.filter((f) => this.state[f].length);
      //   const allFilters = enabledFilters.every((f) => this.state[f] == p[f]);
      //   return p.survey && p.survey.length > 0 && allFilters;
      // })
      .map((ps) => {
        // Demographics
        const group = this.getByName(ps.survey, 'group');
        const groups = organization_groups.reduce((acc, og, ogi) => {
          acc[`group_${ogi}`] = this.getByName(ps.survey, `group_${ogi}`);
          return acc;
        }, {});
        const gender = this.getByName(ps.survey, 'gender');
        // Structure position
        const structureResponses = this.getByType(ps.survey, 'structure');
        const structureAvg =
          structureResponses.length > 0
            ? structureResponses.reduce(
                (acc, question) => acc + question.response,
                0
              ) / structureResponses.length
            : 0;
        // console.log({structureAvg})

        // Strategy position
        let strategyResponses = this.getByType(ps.survey, 'strategy');

        // // Temp Test
        // strategyResponses = strategyResponses.map((q) => {
        //   altsCounterStrategy[q.response - 1]++;
        //   if (q.response === 2) q.response = 1;
        //   if (q.response === 3) q.response = 4;
        //   return q;
        // });

        const strategyAvg =
          strategyResponses.length > 0
            ? strategyResponses.reduce(
                (acc, question) => acc + question.response,
                0
              ) / strategyResponses.length
            : 0;
        const fullname = `${ps.name} ${ps.lastname}`;
        const authorName = fullname.trim().length > 0 ? fullname : ps.email;
        // Return position
        // console.log('avgs', structureAvg, strategyAvg)
        return {
          x: structureAvg,
          y: strategyAvg,
          author: authorName,
          group,
          ...groups,
          gender,
          marker: { radius: 10 },
        };
      });

    // console.log({ respondants });
    // console.log({ altsCounterStructure });
    // console.log({ altsCounterStrategy });

    const genders = ['Male', 'Female', 'Other', 'Prefer not to say'];
    const series = respondants.reduce((acc, r) => {
      if (grouping === 'All') {
        if (!acc[0]) acc[0] = { id: 'all', name: 'All', data: [] };
        acc[0].data.push({ ...r });
        return acc;
      }
      const sval = r[grouping];
      if (!acc[`${grouping}_${sval}`]) {
        // Series label
        let sname =
          grouping === 'group'
            ? isNaN(sval)
              ? sval
              : organization_groups[sval]
            : sval;
        sname = grouping === 'gender' ? genders[sval - 1] : sname;
        acc[`${grouping}_${sval}`] = { id: sval, name: sname, data: [] };
      }
      if (sval) acc[`${grouping}_${sval}`].data.push({ ...r });
      return acc;
    }, {});

    let groupedSeries = Object.values(series).filter((s) => s && s.id);
    if (avg) {
      groupedSeries = groupedSeries.map((s) => {
        const avgPos = s.data.reduce(
          (acc, d) => {
            acc.x += d.x;
            acc.y += d.y;
            return acc;
          },
          { x: 0, y: 0 }
        );

        s.data = [
          {
            x: avgPos.x / (s.data.length === 0 ? 1 : s.data.length),
            y: avgPos.y / (s.data.length === 0 ? 1 : s.data.length),
            marker: { radius: 10 },
            author: s.name,
          },
        ];
        return s;
      });
    }

    // console.log({ avg, groupedSeries });

    const nonEmptyRespondants = respondants.filter((r) => r.x).length;
    const overallSumX = respondants.reduce((acc, r) => acc + r.x, 0);
    const overallAvgX = nonEmptyRespondants
      ? overallSumX / nonEmptyRespondants
      : 0;
    const overallSumY = respondants.reduce((acc, r) => acc + r.y, 0);
    const overallAvgY = nonEmptyRespondants
      ? overallSumY / nonEmptyRespondants
      : 0;
    const Insights = (
      <div className='card shadow'>
        <div className='card-body text-left'>
          <small>
            <b>Avg Strategy Score</b>: {overallAvgX.toFixed(2)}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>Avg Organization Score</b>: {overallAvgY.toFixed(2)}
          </small>
          <h4 className='mt-4'>
            Greatest Concentration: {overallAvgY > 2.5 ? 'Top' : 'Bottom'}{' '}
            {overallAvgX > 2.5 ? 'Right' : 'Left'}
          </h4>
          <h5 className='mt-4'>
            What is notable for companies in this quadrant:
          </h5>
          {/* Left Bottom */}
          {overallAvgX < 2.5 && overallAvgY < 2.5 && (
            <p className='mb-0'>
              The respondents in your organization largely fall into the
              southwest quadrant of the table. This suggests that your team
              perceives both its strategy and structure to be more
              exploiter-like. However, it is still important to assess how
              respondents perceive the organization's environment (see
              Directions section above). In an environment perceived as Stable,
              your strategy and structure are likely working in unison to
              deliver positive results. In an environment perceived as Volatile,
              your organization may want to verify its current goals and
              approach.
            </p>
          )}
          {/* Left Top */}
          {overallAvgX < 2.5 && overallAvgY > 2.5 && (
            <p className='mb-0'>
              Many groups, teams, and companies fall into the northwest
              quadrant, or more simply northwest of the 45 degree line of
              alignment. Your organization is in good company since this is the
              most common form of misalignment. It indicates that the strategy
              being pursued is more explorer-like in practice. However, your
              team perceives the organizational structure to be more
              exploiter-like. As a result, your organization's structure may be
              too rigid to fully deliver on the strategic goals and aims of the
              organization.
            </p>
          )}
          {/* Right Bottom */}
          {overallAvgX > 2.5 && overallAvgY < 2.5 && (
            <p className='mb-0'>
              The average response for your organization falls in the southeast
              quadrant. This suggests that there may be some perceived
              misalignment between your organization's strategic goals and its
              organizational structure. Specifically, respondents from your
              organization may perceive that structure may be too fluid or
              explorer-like to be able to fully deliver on the strategic goals
              and aims of the organization (which are more exploiter-like).
            </p>
          )}
          {/* Right Top */}
          {overallAvgX > 2.5 && overallAvgY > 2.5 && (
            <p className='mb-0'>
              The respondents in your organization largely fall into the
              northeast quadrant of the table. This suggests that your team
              perceives both its strategy and structure to be more
              explorer-like. However, it is still important to assess how
              respondents perceive the organization's environment (see
              Directions section above). In an environment perceived as
              Volatile, your strategy and structure are likely working in unison
              to deliver positive results. In an environment perceived as
              Stable, your organization may want to verify its current goals and
              approach.
            </p>
          )}
        </div>
      </div>
    );

    return (
      <div className='row'>
        <div className='col-4 d-print-none'>
          <iframe
            title='video'
            src='https://player.vimeo.com/video/476332666'
            width='90%'
            height='360'
            frameBorder='0'
            allow='autoplay; fullscreen'
            allowFullScreen></iframe>
        </div>
        <div className='d-none d-print-block col-2'></div>
        <div className='col'>
          {/* <select
            name='grouping'
            className='form-control mb-4'
            onChange={this.handleChange}>
            <option>All</option>
            {organization_groups.map((g, gi) => {
              return (
                <option
                  value={`group_${gi}`}
                  selected={g === grouping ? true : false}>
                  {g.name ? g.name : g}
                </option>
              );
            })}
          </select> */}
          {/* <div className='form-check d-print-none'>
            <input
              className='form-check-input'
              type='checkbox'
              name='avg'
              onChange={this.handleChange}
              checked={avg}
            />
            <label className='form-check-label'>Group Average</label>
          </div> */}
          <HighchartsReact
            containerProps={{ style: { margin: '0 auto' } }}
            highcharts={Highcharts}
            options={{
              title: { text: '' },
              chart: {
                type: 'scatter',
                height: '400px',
              },
              credits: { enabled: false },
              legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                enabled: false,
              },
              xAxis: {
                title: {
                  text: 'Agility: Strategy',
                  useHTML: true,
                  y: -10,
                  style: {
                    display: 'inline-block',
                    color: '#FFF',
                    padding: '0 8px 2px 8px',
                    backgroundColor: '#3577f2',
                  },
                },
                allowDecimals: false,
                min: 1,
                max: 4,
                labels: { enabled: false },
                plotLines: [
                  {
                    color: 'black',
                    dashStyle: 'dot',
                    width: 2,
                    value: 2.5,
                  },
                ],
                plotBands: [
                  {
                    color: 'rgba(0, 0, 0, 0)',
                    from: 1,
                    to: 2.5,
                    zIndex: 1,
                    label: {
                      text: 'Less',
                      y: 15,
                      align: 'center',
                      verticalAlign: 'bottom',
                      style: {
                        color: '#AAA',
                      },
                    },
                  },
                  {
                    color: 'rgba(0, 0, 0, 0)',
                    from: 2.5,
                    to: 4,
                    zIndex: 1,
                    label: {
                      text: 'More',
                      y: 15,
                      align: 'center',
                      verticalAlign: 'bottom',
                      style: {
                        color: '#AAA',
                      },
                    },
                  },
                ],
              },
              yAxis: {
                title: {
                  text: 'Agility: Organization',
                  useHTML: true,
                  x: 10,
                  style: {
                    display: 'inline-block',
                    color: '#FFF',
                    padding: '0 8px 2px 8px',
                    backgroundColor: '#3577f2',
                  },
                },
                allowDecimals: false,
                min: 1,
                max: 4,
                labels: { enabled: false },
                plotLines: [
                  {
                    color: 'black',
                    dashStyle: 'dot',
                    width: 2,
                    value: 2.5,
                  },
                ],
                plotBands: [
                  {
                    color: 'rgba(0, 0, 0, 0)',
                    from: 1,
                    to: 2,
                    zIndex: 1,
                    label: {
                      text: 'Less',
                      y: 20,
                      x: -15,
                      rotation: -90,
                      align: 'left',
                      style: {
                        color: '#AAA',
                      },
                    },
                  },
                  {
                    color: 'rgba(0, 0, 0, 0)',
                    from: 3,
                    to: 4,
                    zIndex: 1,
                    label: {
                      text: 'More',
                      y: 20,
                      x: -15,
                      rotation: -90,
                      align: 'left',
                      style: {
                        color: '#AAA',
                      },
                    },
                  },
                ],
              },
              tooltip: {
                formatter: function () {
                  const p = this.point;
                  return `<b>${p.author}</b><br/>
                Strategy: ${p.y.toFixed(2)}<br/>
                Structure: ${p.x.toFixed(2)}`;
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0,
                  borderWidth: 0,
                  groupPadding: 0,
                  shadow: false,
                },
                series: {
                  cursor: 'pointer',
                  events: {
                    click: function (event) {
                      // if (popup === true)
                      //   self.setGroupModal(event.point.pair_index);
                    },
                  },
                },
              },
              series: [
                ...groupedSeries,
                {
                  id: 0,
                  name: 'Alignement',
                  type: 'line',
                  color: '#EEE',
                  enableMouseTracking: false,
                  marker: {
                    enabled: false,
                  },
                  showInLegend: false,
                  data: [
                    { x: 0, y: 0 },
                    { x: 1, y: 1 },
                    { x: 2, y: 2 },
                    { x: 3, y: 3 },
                    { x: 4, y: 4 },
                    { x: 5, y: 5 },
                    { x: 6, y: 6 },
                  ],
                },
              ],
            }}
          />
        </div>
        <div className='d-none d-print-block col-4'></div>
        <div className='col-10 offset-1'>
          {players.length > 0 ? Insights : null}
        </div>
      </div>
    );
  }
}
export default AlignmentMatrix;
